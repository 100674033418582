<template>
  <form v-on:submit.prevent="resetPassword()" class="pb-4">
    <form-group>
      <label class="form-label" for="n_p_1">{{ $t('Password') }}</label>
      <div class="form-control-wrap">
        <a href="javascript:;" class="form-icon form-icon-right passcode-switch" @click="new_password.pass_visible= !new_password.pass_visible" tabindex="-1">
          <nio-icon icon="ni-eye" v-if="!new_password.pass_visible"></nio-icon>
          <nio-icon icon="ni-eye-off" v-else></nio-icon>
        </a>
        <input :type="new_password.pass_visible ? 'text': 'password'"
               class="form-control form-control-lg"
               id="n_p_1" placeholder="********"
               v-model="new_password.password"
               autocomplete="off"/>
      </div>
    </form-group>
    <form-group>
      <label class="form-label" for="r_password">{{ $t('Repeat password') }}</label>
      <div class="form-control-wrap">
        <a href="javascript:;" class="form-icon form-icon-right passcode-switch" @click="new_password.confirm_pass_visible= !new_password.confirm_pass_visible" tabindex="-1">
          <nio-icon icon="ni-eye" v-if="!new_password.confirm_pass_visible"></nio-icon>
          <nio-icon icon="ni-eye-off" v-else></nio-icon>
        </a>
        <input :type="new_password.confirm_pass_visible ? 'text': 'password'"
               class="form-control form-control-lg"
               id="r_password"
               placeholder="********"
               v-model="new_password.confirm"
               autocomplete="off"/>
      </div>
    </form-group>
    <form-group>
      <form-submit-button :text="$t('Submit')"></form-submit-button>
    </form-group>
  </form>
</template>

<script>
import FormGroup from "@core/layouts/form-group/FormGroup";
import FormSubmitButton from "@/views/auth/components/FormSubmitButton";
import {reactive} from "vue";
import {toastController} from "@ionic/vue";
import useCommonFunc from "@core/comp-functions/common";
import {useI18n} from "vue-i18n";

export default {
  name: "NewPasswordForm",
  components: {FormSubmitButton, FormGroup},
  emits:['resetPassword'],
  setup(props, {emit}){

    const {t} = useI18n()
    const { passwordValidationRegex } = useCommonFunc()
    let new_password = reactive({
      password: '',
      confirm: '',
      pass_visible: false,
      confirm_pass_visible: false,
    })
    const resetPassword = async ()=>{
      let toast = await toastController.create({duration: 3000, color: 'danger', position: 'top'})
      if(!passwordValidationRegex.test(new_password.password)){
        toast.message = t('invalid_password')
        await toast.present()
        return false
      }
      else if( new_password.password !== new_password.confirm){
        toast.message = t('registration.password_must_match')
        await toast.present()
        return false;
      }
      emit('resetPassword', new_password);
    }

    return{
      new_password,
      resetPassword,
    }
  },
}
</script>
