<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <div class="nk-split nk-split-page nk-split-md">
        <div class="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
          <div class="nk-block nk-block-middle nk-auth-body">
            <div class="brand-logo pb-5" v-if="!$isHybridPlatform">
              <brand-logo></brand-logo>
            </div>
            <div class="nk-block-head">
              <div class="nk-block-head-content">
                <h5 class="nk-block-title">{{ $t('Create password')}}</h5>
                <div class="nk-block-des">
                  <p>{{$t('ap_agent.complete_reg.msg1')}}</p>
                </div>
              </div>
            </div>

            <new-password-form @reset-password="createPassword"></new-password-form>

            <div class="form-note-s2 pt-2">
              <router-link :to="{name:'Login'}" class="fw-bold">{{$t('forgot_password.return_btn')}}</router-link>
            </div>
          </div>
          <auth-footer></auth-footer>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, IonContent, loadingController, toastController} from "@ionic/vue"
import { BrandLogo } from "@core/components"
import {useStore} from "vuex";
import { useRoute, useRouter } from "vue-router"
import {defineComponent} from "vue";
import axios from "@/libs/axios"
import AuthFooter from "@/views/auth/components/AuthFooter";
import {useI18n} from "vue-i18n";
import NewPasswordForm from "@/views/auth/components/NewPasswordForm";

export default defineComponent({
  components: {NewPasswordForm, AuthFooter, BrandLogo, IonPage, IonContent},
  setup(){

    const store = useStore()
    const i18n = useI18n()

    const route = useRoute()
    const router = useRouter()

    const createPassword = async (data) => {

      let toast = await toastController.create({duration: 3000, color: 'danger', position: 'top'})

      let loader = await loadingController.create({message: i18n.t('Please wait...')})
      await loader.present()

      axios.post('/api/password/'+route.params.role+'/complete_reg', {
        password: data.password,
        key: route.params.activation_key,
        locale: store.state.appConfig.lang,
        client_id: process.env.VUE_APP_API_CLIENT_ID}
      )
      .then(r=>{
        if(r.data.access_token){
          store.commit('auth/AUTH_SUCCESS', r.data)
          let tmp = {
            'advisor': 'Home',
            'ap_agent': 'apAgentHome',
            'company': 'companyHome',
          }
          router.push({name: tmp[route.params.role]})
        }
        else{
          toast.message = r.data.message
          toast.present()
        }
      })
      .catch(er=>{
        toast.message = er.response ? er.response.status+' '+er.response.statusText : er.toString()
        toast.present()
      })
      .then(() => loader.dismiss())
    }

    return {
      createPassword,
      router,
    }
  },
})
</script>
